<template>
    <div className="Versions">
      <div class="container">
          <h3 class="p-3 text-center"><i class="bi bi-window-desktop"></i> Applications in Market Places</h3>
          <loader :active="loaderActive" message="Please wait..." />
          <div style="width: 100%; text-align: center; display: inline-block;">
            
          <table class="table table-striped table-bordered table-hover" id="result" style="width: 100%">
              <thead>
                  <tr>
                      <th rowspan="2">Application</th>
                      <th rowspan="2">Platform</th>
                      <th rowspan="2">Version</th>
                      <th rowspan="2">Market</th>
                      <th colspan="3">TEST Mode</th>
                      <th colspan="3">PROD Mode</th>
                  </tr>
                  <tr>
                    <th>API</th>
                    <th>Service Desk</th>
                    <th>Pages</th>
                    <th>API</th>
                    <th>Service Desk</th>
                    <th>Pages</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="application in applications" :key="application.id">
                      <td class="text-nowrap">{{application.name}}</td>
                      <td >{{application.platform}}</td>
                      <td>{{application.version }}</td>
                      <td v-html="getMarketCheckmark(application.marketplace)"></td>
                      <td><span :class="getLabel(application.data, 'Test', 'API')">{{getValueFor(application.data, "Test", "API")}}</span></td>
                      <td><span :class="getLabel(application.data, 'Test', 'SD')">{{getValueFor(application.data, "Test", "SD")}}</span></td>
                      <td v-html="getPagesList(application.data, 'Test')"></td>
                      <td><span :class="getLabel(application.data, 'Prod', 'API')">{{getValueFor(application.data, "Prod", "API")}}</span></td>
                      <td><span :class="getLabel(application.data, 'Prod', 'SD')">{{getValueFor(application.data, "Prod", "SD")}}</span></td>
                      <td v-html="getPagesList(application.data, 'Prod')"></td>
                  </tr>
              </tbody>
          </table>
          </div>
      </div>    
    </div>
  </template>
  
  <script>
  import Loader from './Loader.vue'
  import loaderMixin from '../mixin/loader.js';
  import titleMixin from '../mixin/titleMixin'
  import $ from 'jquery'
  
  export default {
    title: "BBCS / Mobile Applications Environments",
    components: {
      Loader
    },
    mixins: [loaderMixin, titleMixin],
    data() {
      return {
        applications: {},
        marketplace: true,
        state: ""
      }
    },
    methods: {
      getApplications() {
        console.log(import.meta.env)
        console.log(process.env)
        this.showLoader()
        $("#result").hide()
        const url = process.env.VUE_APP_API_URL + "/applications_checkup?marketplace=" + this.marketplace + "&state=" + this.state
        console.log(url)
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.text())
          .then((data) => {
            this.hideLoader()
            $("#result").show("1200")
            if (JSON.parse(data).result != undefined) {
              this.applications = JSON.parse(data).result.sort(compare)
              console.log(this.applications)
            }
          });
      },
      getLabel(value, env, module) {
        const preValue = this.getValueFor(value, env, module)
        return this.getLabelInternal(preValue, env)
      },
      getMarketCheckmark(value) {
        return value ? "<i class='bi bi-check-circle-fill' style='color: #2AA744;'></i>" : ""
      },
      getLabelInternal(value, env) {
        if (value == undefined) return ""
        if (env == "Test") {
          if (value == "Test") return "badge badge-success"
          if (value == "UAT") return "badge badge-warning"
          if (value == "Production") return "badge badge-warning"
          if (value == "Baseline") return "badge badge-warning"
          if (value == "Timeshift") return "badge badge-warning"
          if (value == "Loadtest") return "badge badge-warning"
        } else {
          if (value == "Test") return "badge badge-danger"
          if (value == "UAT") return "badge badge-danger"
          if (value == "Baseline") return "badge badge-danger"
          if (value == "Timeshift") return "badge badge-danger"
          if (value == "Production") return "badge badge-success"
          if (value == "Loadtest") return "badge badge-danger"
        }
      },
      getPagesList(value, env) {
        const preValue = this.getValueFor(value, env, "PAGES")
        var result = '<div class="container">'
        if (preValue == undefined) return result
        for (const element of preValue) {
          result += '<div class="row justify-content-between flex-nowrap"><div class="col-5 text-sm-left text-nowrap">' + element.title + ":</div><div class='col-4'><span class='" + this.getLabelInternal(element.env, env) + "'>"+ element.env  +"</span></div></div>" 
        }
        result += "</div>"
        return result
      },
      getValueFor(value, env, module) {
        if (value == undefined) return undefined
        if (env == "Test" && value.Test == undefined) return undefined
        if (env == "Prod" && value.Production == undefined) return undefined 
        var preValue 
        if (env == "Test") {
          preValue = value.Test
        } else if (env == "Prod") {
          preValue = value.Production
        } else if (env == "Baseline") {
          preValue = value.Baseline 
        } else if (env == "Timeshift") {
          preValue = value.Timeshift
        }
  
        if (module == "API") {
          return preValue.base 
        } else if (module == "SD") {
          return preValue.bypasslicenselogin
        } else if (module == "PAGES") {
          return preValue.pages
        }
      }
    },
    mounted () {
     this.getApplications()
     $("#result").hide()
    },
    created() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('all')) {
        this.marketplace = false
        console.log(this.marketplace)
      }
      if (urlParams.has('state')) {
        this.state = urlParams.get('state')
      }
    }
  };
  
  function compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  </script>
  
  
  
  <style>
  body,
  html {
    height: 100vh;
    display: grid;
    place-items: center;
  }
  #root {
    margin: auto;
  }
  .Versions {
    text-align: center;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
  button {
    font-size: 48px;
  }
  </style>
  