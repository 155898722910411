import { createRouter, createWebHistory } from 'vue-router'
import Versions from '../views/VersionsView.vue'
import AppLink from '../views/AppLink.vue'

const routes = [
  {
    path: '/versions',
    name: 'Versions',
    component: Versions
  },
  { path: '/a',
    name: 'AppLink',
    component: AppLink
  },
  { path: '/a/:data',
    name: 'AppLink',
    component: AppLink
  },
  {
    path: '/config-update',
    name: 'Config',
    component: () => import('../views/Config.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
