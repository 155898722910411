<template>
    <v-container fluid style="margin: 5%; padding: 0px; width: 100%">
      <v-layout wrap>
        <loader :active="loaderActive" message="Please wait..." />
        <v-alert elevation="3" border="top" :type="alertType" dismissible prominent dark v-model="alert" :title="alertTitle" :text="alertMessage">
        </v-alert>       
        </v-layout>
        </v-container>
  </template>

  <script>
    import Loader from './Loader.vue'
    import loaderMixin from '../mixin/loader.js';
    import titleMixin from '../mixin/titleMixin'
    import $ from 'jquery'

    export default {
      title: "DeepLinking",
    components: {
      Loader
    },
    mixins: [loaderMixin, titleMixin],
    data() {
      return {
        alertMessage: "Error occured!",
        alertTitle: "Error",
        alertType: "error",
        alert: false,
      }
    },
    methods: {
      showAlert(title, text) {
        this.alertMessage = text
        this.alertTitle = title
        this.alert = true
    
      },
      async deepLink() {
        $('#root').hide()
        this.showLoader()
        this.alertVisible = false
        var data = this.$route.query.data
        console.log(data)
        if (data == undefined) {
          data = this.$route.params.data
          if (data == undefined) {
            this.showAlert('Error', 'No data provided.')
            return
          }
        }
        const base64url = require('base64url');
        console.log(data)
        console.log(base64url.decode(data))
        try {
            
            const url = new URL(base64url.decode(data))
            console.log(url)
            const matchRex = /(gooutdoors|golaw)/
            if (!url.protocol.match(matchRex)) {
                this.showAlert('Error', 'Deep link is wrong.')
                return
            }

            setTimeout(function () { if (document.hasFocus()) { window.location = url.href } }, 500);
            this.hideLoader();
        } catch {
            this.showAlert('Error', 'Deep link is wrong.')
            return
        }
        

      }
    },
    mounted () {
     this.deepLink()
    },
    created() {
      
    }
  };
    </script>
  
  <style>
  body,
  html {
    height: 100vh;
    display: grid;
    place-items: center;
  }
  
  .v-alert {
    position: fixed;
    top: 10%;
    left: 50%;
    min-height: 150px;
    transform: translate(-50%, -50%);
    margin: 0 auto; 
  }
  
  .json-container {
    min-width: 1200px;
    margin-top: 20px;
    max-width: 1500px;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  
  #app {
    width: 100%;
  }
  
  #root {
    margin: auto;
  }
  #change {
    text-align: left;
    background-color:white;
  }
  #change pre {
    background-color: aliceblue;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
  button {
    font-size: 48px;
  }
  
  </style>